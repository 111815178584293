import React from "react";

function Pick() {
  return (
    <div className="pick">
      <h1>Pick</h1>
    </div>
  );
}

export default Pick;
