import React from "react";
import "./Profile.css"

function Profile() {
  return (
    <div className="profile-page">
    <div className="profile-container">
      <div className="profile-image"> <img src="https://images.unsplash.com/photo-1563805042-7684c019e1cb" /></div>
    <h1>First Last</h1>
    <h4>Email</h4>
    <h4>Home Team</h4>
    <button className="profile-button">Edit Profile</button>
    <button className="profile-button">Reset Password</button>
  </div>
  </div>
  );
}

export default Profile;
