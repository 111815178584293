import React from "react";

const Login = (props) => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    handleLogin,
    handleSignUp,
    passwordError,
  } = props;

  return (
    <div className="mainpage">
      <div className="container">
        <div className="form" id="login">
          <h1 className="form__title">TOD-IPL League</h1>
          <div className="form__message--error">{passwordError}</div>
          <div className="form__input-group">
            <input
              type="text"
              className="form__input"
              autoFocus
              placeholder="Email"
              id="loginEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <div className="form__input-error-message"></div>
          </div>
          <div className="form__input-group">
            <input
              type="password"
              className="form__input"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
          </div>
          <button className="form__button" onClick={handleLogin}>
            Sign In
          </button>
          <p className="form__link">
            <a href="./" id="linkCreateAccount" className="form__link">
              Don't have an account? Create account.
            </a>
          </p>
        </div>
        <div className="form form--hidden" id="createAccount">
          <h1 className="form__title">Create Account</h1>
          <div className="form__message--error">{passwordError}</div>
          <div className="form__input-group">
            <input
              type="text"
              id="createAccountEmail"
              className="form__input"
              autoFocus
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <div className="form__input-error-message"></div>
          </div>
          <div className="form__input-group">
            <input
              type="password"
              id="createAccountPassword"
              className="form__input"
              placeholder="Password"
            ></input>
            <div className="form__input-error-message"></div>
          </div>
          <div className="form__input-group">
            <input
              type="password"
              id="createAccountConfirmPassword"
              className="form__input"
              placeholder="Confirm Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
            <div className="form__input-error-message"></div>
          </div>
          <button className="form__button" onClick={handleSignUp}>
            Continue
          </button>
          <p className="form__link">
            <a href="./" id="linkLogin" className="form__link">
              Already have an account? Sign in.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
